<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <template v-if="showRedirectCard">
                <v-card>
                    <v-toolbar dense flat :color="primaryColor" dark>
                        <v-toolbar-title>Account List</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <p class="mb-0">
                            <v-progress-circular :color="primaryColor" indeterminate width="2" size="16" class="mr-2"></v-progress-circular>
                            <span v-if="redirectURL">Redirecting to <a :href="redirectURL">account list</a>...</span>
                        </p>
                    </v-card-text>
                </v-card>
                <p class="mt-6 grey--text text--darken-2 text-center">Don't have an account yet? <router-link :to="{ name: 'signup', query: { email: this.$route.query.email } }">Sign up</router-link></p>
            </template>
            <v-alert type="error" v-if="isError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { SSO_TAG_UNICORNSPRINGS_PORTAL } from '@/sdk/constants';

export default {
    components: {
    },
    data: () => ({
        showRedirectCard: false,
        redirectURL: null,
        loginError: false,
        serverError: false,
        clientError: false,
        unknownError: false,
    }),
    computed: {
        ...mapState({
            focus: (state) => state.focus,
            session: (state) => state.session,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isError() {
            return this.serverError || this.clientError || this.unknownError || this.loginError;
        },
    },
    watch: {
    },
    methods: {
        redirect(redirectURL) {
            this.redirectURL = redirectURL;
            // show a link after 2 seconds in case auto-redirect fails or takes a long time
            setTimeout(() => {
                this.showRedirectCard = true;
            }, 2000);
            // use replace so that when user taps 'back' button from there, they won't
            // end up being redirected again to where they just wanted to come back from
            if (typeof window.location.replace === 'function') {
                window.location.replace(redirectURL);
            } else {
                window.location.href = redirectURL;
            }
        },
        async navigateToAccountList() {
            try {
                this.error = false;
                this.$store.commit('loading', { navigateToAccountList: true });
                // const response = await this.$client.account(this.$route.params.accountId).unicornsprings.connect({ intent: 'billing' });
                const response = await this.$client.main().authn.ssoRedirect({ to_tag: SSO_TAG_UNICORNSPRINGS_PORTAL, intent: 'account_list' });
                // console.log(`response ${JSON.stringify(response)}`); // {"redirect":"https://...","redirect_max_seconds":300}
                if (response?.redirect) {
                    // console.log(`navigateToAccountList: redirect ${response.redirect}`);
                    this.redirect(response.redirect);
                }
            } catch (err) {
                console.error('navigateToAccountList: failed to get link to Unicorn Springs', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to connect with Unicorn Springs' });
            } finally {
                this.$store.commit('loading', { navigateToAccountList: false });
            }
        },
    },
    mounted() {
        this.navigateToAccountList();
    },
};
</script>
